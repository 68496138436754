

import {Link, Trans, useTranslation, useI18next}
from 'gatsby-plugin-react-i18next';
import Papa from 'papaparse';


//import kakieramail from "../img/R_kariera_10/kariera_tlo.jpg";
//import kakieramail from "../img/R_kariera_10/kakieramail.png";
//

import React, { useEffect, useState } from 'react';
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";


export default function Header(props) {

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (typeof window !== 'undefined') { // Ensure it's running in the browser
            fetch('/jobs.csv')
                .then(response => response.text())
                .then(csvText => {
                    Papa.parse(csvText, {
                        header: false, 
                        skipEmptyLines: true,
                        complete: (result) => {
                            setJobs(result.data);
                        }
                    });
                })
                .catch(error => console.error('Error loading CSV:', error));
        }
    }, []);

    return (
            <React.Fragment>

                <section id="career" class="career firstSection">

                    <h1>{i18next.t('CareerH1')}</h1>
                    <p>{i18next.t('CareerP')}</p>

                    <div class="link" onClick={() => window.location = 'mai' + '' + 'lto:kari' + 'era@inst' + 'anta.pl'}>
                    </div>

                    <h1>Aktualne rekrutacje</h1>
                    <p>
                    <table class="table-career">
                        <tbody>
                            {jobs.map((job, index) => (
                                <tr key={index}>
                                    <td><a className="career-link" href={job[0]}>{job[1]}</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </p>

                </section>



            </React.Fragment>

    )


}
